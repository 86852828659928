import React from 'react'
import { HomeView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Home'} />
}

const IndexPage = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid
  return <HomeView data={data} />
}

export const homeQuery = graphql`
  query homeQuery {
    contentfulCaseStudyGrid(page: { eq: "Home" }) {
      ...CaseStudyGrid
    }
  }
  fragment CaseStudyGrid on ContentfulCaseStudyGrid {
    id
    layout {
      hoverColor
      title
      desc: coverDescription
      urlSlug
      coverImage {
        gatsbyImageData(quality: 100)
      }
    }
  }
`

export default IndexPage
